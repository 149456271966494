<template lang="pug">
  .HeaderComponent
    header(
      class="pt-8"
      v-if="showFixed"
      :class="showFixed ? 'withoutShowFixed' : 'showFixed'"
    ).Header.bg-primary_other.p-10.relative.bg-gradient-to-r.from-primary.to-primary_other
      img(class="Header__image" :src="Logo" alt="logo" @click="handleGoToHome" v-if="Logo").mx-auto 
      button(
        @click="handleGoBack"
        v-if="(!loading && trip !== 'DateAgended') && trip !== 'Home'"
        class="Arrow"
      ).rounded-bd-complete.w-10.h-10.flex.items-center.justify-center.border-none.outline-none.absolute
        .material-icons.text-gray chevron_left
      p(class="versionStyles").absolute
        small.text-white {{ version }}
    header(
      class="pt-2"
      :class="showFixed ? 'withoutShowFixed' : 'showFixed'"
      v-else
    ).Header.bg-primary_other.p-10.relative.bg-gradient-to-r.from-primary.to-primary_other
      img(class="Header__image__fixed" :src="Logo" alt="logo" @click="handleGoToHome").mx-auto 
      button(
        @click="handleGoBack"
        v-if="!loading && trip !== 'DateAgended'"
        class="ArrowSmall"
      ).rounded-bd-complete.w-10.h-10.flex.items-center.justify-center.border-none.outline-none.absolute
        .material-icons.text-gray chevron_left
      p(class="versionStylesFixed").absolute
        small.text-white {{ version }}
      button(
        @click="handleGoToHome"
        v-if="(!loading && trip !== 'DateAgended') && trip !== 'Home'"
        class="ArrowFixed"
      ).rounded-bd-complete.w-10.h-10.flex.items-center.justify-center.border-none.outline-none.absolute
        .material-icons.text-primary_other home
</template>

<script>
import { mapActions, mapState } from "vuex";
import LogoCafam from "@/assets/images/logo_cafam@2x.png";
import LogoSmall from "@/assets/images/logo_cafam_small.png";
import loadingMessage from '../../utils/loadingMessage';

export default {
  name: "Header",
  props: {
    handleGoBack: Function,
    showFixed: Boolean,
  },

  data: () => ({
    LogoCafam,
    // version: process.env.VUE_APP_VERSION || "0.0.1",
    version: process.env.PACKAGE_VERSION,
    LogoSmall,
  }),

  computed: {
    ...mapState({
      env: state => state.environment.environment.env,
      loading: (state) => state.formDocuments.loading,
      trip: (state) => state.root.trip,
    }),
    Logo() {
      return this.env?.VUE_APP_LOGO;
    }
  },

  methods: {
    ...mapActions("root", [
      "changeTrip",
      "handleShowModal"
    ]),
    handleGoToHome() {
      this.handleShowModal(loadingMessage({
        state: "",
        title: "Ir al inicio",
        description: "Haz click para empezar de nuevo desde el inicio.",
        specialButtonText: "Ir al inicio",
        iWantGoToHome: true
      }));
    }
  },
}
</script>

<style scoped>
  .Header {
    border-radius: 0 0 25px 25px;
    transform: translateY(-160px);
    transition: 1s transform;
    animation: fadeInY 0.35s ease-out alternate forwards;
  }
  .Header__image {
    max-width: 240px;
    height: 55px;
    /* transition: 0.2s all; */
  }
  .Arrow {
    background-color: #88a8d082;
    outline: none;
    top: 30px;
    left: 20px;
  }
  .ArrowFixed {
    background-color: #FFFFFF;
    outline: none;
    top: 15px;
    right: 20px;
  }
  .ArrowSmall {
    background-color: #88a8d082;
    outline: none;
    top: 15px;
    left: 20px;
  }
  .versionStyles {
    top: 30px;
    left: 20px;
  }
  .versionStylesFixed {
    top: -1px;
    left: 30px;
    font-size: 12px;
  }
  .Header__image__fixed {
    max-width: 97px;
    margin-top: 3px;
    max-width: 240px;
    height: 55px;
  }
  .withoutShowFixed {
    position: fixed;
    z-index: 99999999999999999;
    top: 0;
    left: 0;
    right: 0;
    max-width: 30rem;
    margin: auto;
    max-height: 115px;
    overflow: hidden;
  }
  .showFixed {
    height: 100%;
    max-height: 71px;
    overflow: hidden;
    max-width: 30rem;
    margin: auto;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 99999999999999999;
    /* transition: 1s all !important; */
  }
</style>